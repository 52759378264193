import { Component } from '@angular/core';
import { SpinnerService } from 'sirc-lib';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  // Version récupérée directement depuis le fichier "package.json"
  public version: string = environment.VERSION;

  constructor(
    private spinnerService: SpinnerService
  ) {}

  public get spinnerVisible(): boolean {
    return this.spinnerService.spinnerVisible;
  }
}
