import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CalculatedDates, Demande, Employeur, LibHttpDemandeService, Remuneration, TransmissionDto } from 'sirc-lib';
import { DemandeSuiviDto } from '../../modeles/dto/demande-suivi-dto.model';
import { ImpressionCerfaDto } from '../../modeles/dto/impression-cerfa-dto.model';
import { LoggedDemand } from '../../shared/model/demand/logged-demand.model';
import { environment } from 'projects/sirc-public/src/environments/environment';

@Injectable({ providedIn: 'root' })
export class DemandeService implements LibHttpDemandeService {
  private readonly ENDPOINT_DEMAND_BASE = environment.hrefBase + '/api/demand';
  private readonly ENDPOINT_DEMAND_MAIL_RESET_PASSWORD = this.ENDPOINT_DEMAND_BASE + '/reset-password';
  private readonly ENDPOINT_DEMAND_CALCULATE_DATE = this.ENDPOINT_DEMAND_BASE + '/calculate/dates';
  private readonly ENDPOINT_DEMAND_SUIVI = this.ENDPOINT_DEMAND_BASE + '/suivi-homologation';

  constructor(
    private readonly http: HttpClient,
  ) { }

  isOnBo(): boolean {
    return false;
  }

  public getByUuid(uuid: string): Observable<Demande> {
    return this.http.get<Demande>(`${this.ENDPOINT_DEMAND_BASE}/${uuid}`).pipe(
      map(dem => new Demande(dem)),
    );
  }

  /**
   * Calculate deadlines
   *
   * @param dateSignature date of signature
   * @param codePostal post code of employer (or emplyee if employer is absent)
   * @param codeInsee insee code of employer (or emplyee)
   */
  calculateDatesForFo(dateSignature: string, codePostal: string, codeInsee: string, dateReception = ''): Observable<CalculatedDates> {
    return this.http.get<CalculatedDates>(this.ENDPOINT_DEMAND_CALCULATE_DATE, {
      params: {
        dateSignature,
        dateReception,
        codeInsee,
        codePostal,
      }
    });
  }

  save(demande: Demande): Observable<Demande> {
    if (!!demande['id'] || demande['id'] === 0) {
      return this.http.post<Demande>(this.ENDPOINT_DEMAND_BASE, demande).pipe(
        map((dem) => new Demande(dem)),
      );
    } else {
      return this.http.put<Demande>(this.ENDPOINT_DEMAND_BASE, demande).pipe(
        map((dem) => new Demande(dem)),
      );
    }
  }

  /**
   * Check if demand exist and change password token is atach to demand
   * @param uuid uuid of demand
   * @param token token of password update
   */
  public demandPasswordExist(uuid: string, token: string): Observable<boolean> {
    return this.http.get<boolean>(`${this.ENDPOINT_DEMAND_BASE}/${uuid}/${token}`);
  }

  /**
   * Define password to demand
   * @param uuid uuid of demand
   * @param token token of password update
   * @param password password to demand
   * @returns Logged information to demand
   */
  public passwordDefinition(uuid: string, token: string, password: string): Observable<LoggedDemand> {
    return this.http.post<LoggedDemand>(`${this.ENDPOINT_DEMAND_BASE}/${uuid}/${token}`, { password });
  }

  public resetPassword(email: string, numberDemand: string, recaptcha: string): Observable<void> {
    return this.http.post<void>(this.ENDPOINT_DEMAND_MAIL_RESET_PASSWORD, {
      email,
      number: numberDemand,
      recaptcha,
    });
  }


  //////////////////////////////// SUIVI HOMOLOGATION ////////////////////////
  getDemandeSuiviOnIntraPass(pass: string): Observable<DemandeSuiviDto> {
    return this.http.get<DemandeSuiviDto>(this.ENDPOINT_DEMAND_SUIVI + '/pass', {
      params: {
        pass,
      }
    });
  }
  getDemandeSuiviOnIntra(numero: string, infos: string): Observable<DemandeSuiviDto> {
    return this.http.get<DemandeSuiviDto>(this.ENDPOINT_DEMAND_SUIVI, {
      params: {
        numero,
        infos,
      }
    });
  }

  getDemandeSuiviOnPublic(numero: string, infos: string): Observable<boolean> {
    return this.http.get<boolean>(this.ENDPOINT_DEMAND_SUIVI + '/exist', {
      params: {
        numero,
        infos,
      }
    });
  }

  downloadAttestations(uuidDemande: string, attestationEmployer: boolean): Observable<Blob> {
    const url = `${this.ENDPOINT_DEMAND_SUIVI}/${uuidDemande}/attestation-${attestationEmployer ? 'employeur' : 'salarie'}`;

    return this.http.get(url, { responseType: 'blob' });
  }
  //////////////////////////////// FIN - SUIVI HOMOLOGATION ////////////////////////


  //////////////////////////////// FORMULAIRE DEMANDE  ////////////////////////
  /**
   * @description: retourne les informations SIENE de l'employeur dont le SIRET a été passé en paramètre
   */
  getWSSieneInfos(siret: string): Observable<Employeur> {
    return this.http.get<Employeur>(this.ENDPOINT_DEMAND_BASE + '/employeur/siret/' + siret);
  }

  public imprimerCerfa(uuidDemande: string, numDemande: string, indemniteLettres: string, brouillon: boolean): Observable<Blob> {
    const imprimCerfaDto = new ImpressionCerfaDto({
      uuid: uuidDemande,
      indemniteLettres,
      isCerfaOfficiel: !brouillon,
    });

    return this.http.post(this.ENDPOINT_DEMAND_BASE + '/cerfa', imprimCerfaDto, { responseType: 'blob' });
  }

  teletransmissionDemande(transmiDto: TransmissionDto, documentCerfa: File[]): Observable<any> {
    const formdata = new FormData();
    for (var i = 0; i < documentCerfa.length; i++) {
      formdata.append('cerfaSigne', documentCerfa[i]);
    }
    formdata.append('uuid', transmiDto.uuid);
    formdata.append('password', transmiDto.password);
    formdata.append('courrielEmployeur', transmiDto.courrielEmployeur);
    formdata.append('courrielSalarie', transmiDto.courrielSalarie);
    formdata.append('dateSignature', moment(transmiDto.dateSignature).format('YYYY-MM-DD'));
    formdata.append('dateFinDelaiRetractation', moment(transmiDto.dateFinDelaiRetractation).format('YYYY-MM-DD'));
    formdata.append('dateEnvisageeRupture', moment(transmiDto.dateEnvisageeRupture).format('YYYY-MM-DD'));
    formdata.append('dateFinDelaiInstruction', moment(transmiDto.dateFinDelaiInstruction).format('YYYY-MM-DD'));

    return this.http.post<Demande>(this.ENDPOINT_DEMAND_BASE + '/teletransmission', formdata);
  }

  //////////////////////////////// FIN - FORMULAIRE DEMANDE  ////////////////////////

  // NOT Implemented method //

  calculateDatesForBo(dateSignature: string, dateReception: string, organismeId: string): Observable<CalculatedDates> {
    throw new Error('Not implemented in FO');
  }

  downloadFichierConventionCollective(codeIdcc: string, remuneration: Remuneration): Observable<Blob> {
    throw new Error('Not implemented in FO');
  }
}
