<div class="container-fluid d-flex justify-content-between">
  <div class="header-row row no-gutters align-items-center w-100">
    <div class="logo">
      <a [routerLink]="['/accueil']">
        <div class="header-logo-box align-items-center">
          <img alt="Ministère du travail, de Santé et des Solidarités" src="/api/logo">
        </div>
      </a>
    </div>

    <div class="header-title-container">
      <span class="title"><strong>TéléRC </strong><span class="version">( v{{version}} )</span></span><br>
      <span class="subtitle">
        Le service de saisie d'une demande d'<span class="bold">homologation de rupture conventionnelle individuelle</span>
      </span>
    </div>
  </div>
</div>

<mat-progress-bar *ngIf="spinnerVisible" mode="indeterminate"></mat-progress-bar>

